<template>
  <div class="app-container">
    <div class="filter-container" style="display: flex;flex-wrap: wrap;">
<!--      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>-->

      <el-input class="filter-item" v-model="listQuery.name" placeholder="姓名" style="width: 220px;" clearable/>

      <el-input class="filter-item" v-model="listQuery.phone" placeholder="手机号码" style="width: 220px;" clearable/>

      <el-select class="filter-item" v-model="listQuery.gender" placeholder="性别" style="width: 120px" clearable>
        <el-option label="男" :value="1"/>
        <el-option label="女" :value="2"/>
      </el-select>

      <el-select class="filter-item" v-model="listQuery.status" placeholder="状态" style="width: 120px" clearable>
        <el-option label="未开始" :value="0"/>
        <el-option label="生效中" :value="1"/>
        <el-option label="已截止" :value="2"/>
      </el-select>

      <el-date-picker v-model="dateArrQuery" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="filter-item" style="display: flex;" clearable></el-date-picker>

      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>

<!--      <el-button class="filter-item" type="success" @click="downloadCateredStudentTemplate" style="margin-left: 0px;">包餐学生模板</el-button>-->

<!--      <el-upload class="filter-item" accept=".xlsx,.xls" action="" :show-file-list="false" :auto-upload="false" :on-change="handleImportCateredStudent">-->
<!--        <el-button type="warning">导入包餐学生</el-button>-->
<!--      </el-upload>-->

      <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="包餐学生列表.xls" worksheet="包餐学生列表">
        <el-button type="success" :loading="exportLoading">导出</el-button>
      </download-excel>
    </div>

    <div v-loading="listLoading">
      <div style="margin-bottom: 20px;font-weight: bold;">男生：</div>

      <el-row :gutter="20">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ boy.issuedQuantity || 0 }}</div>
            <div class="subtitle">发放数量</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ boy.usedQuantity || 0 }}</div>
            <div class="subtitle">使用数量</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ boy.remainingQuantity || 0 }}</div>
            <div class="subtitle">剩余数量</div>
          </el-card>
        </el-col>
      </el-row>

      <div style="margin-bottom: 20px;font-weight: bold;">女生：</div>

      <el-row :gutter="20">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ girl.issuedQuantity || 0 }}</div>
            <div class="subtitle">发放数量</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ girl.usedQuantity || 0 }}</div>
            <div class="subtitle">使用数量</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ girl.remainingQuantity || 0 }}</div>
            <div class="subtitle">剩余数量</div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="ID" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="openid" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.openid }}
        </template>
      </el-table-column>

      <el-table-column label="昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.nickname }}
        </template>
      </el-table-column>

      <el-table-column label="姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.name }}
        </template>
      </el-table-column>

      <el-table-column label="手机号码" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.phone }}
        </template>
      </el-table-column>

      <el-table-column label="性别" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.gender === 1" type="primary">男</el-tag>
          <el-tag v-else-if="scope.row.gender === 2" type="danger">女</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="包餐备注名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.catered_name }}
        </template>
      </el-table-column>

      <el-table-column label="剩余次数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.num }}
        </template>
      </el-table-column>

      <el-table-column label="开始日期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.start_date }}
        </template>
      </el-table-column>

      <el-table-column label="结束日期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.end_date }}
        </template>
      </el-table-column>

      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="info">未开始</el-tag>
          <el-tag v-else-if="scope.row.status === 1" type="success">生效中</el-tag>
          <el-tag v-else-if="scope.row.status === 2" type="danger">已截止</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" min-width="150" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="250" align="center" fixed="right" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>

          <el-button type="success" size="mini" @click="handleCateredStudentOrder(scope.row)">消费记录</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30,50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <!-- 详情 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="150px">
        <el-form-item v-if="!form.id" label="用户" prop="user_id">
          <el-select v-model="form.user_id" placeholder="输入昵称/手机号/姓名进行搜索" filterable remote :remote-method="userFilter"  :loading="userFilterLoading" style="width:340px" clearable>
            <el-option v-for="item in users" :key="item.id" :label="item.nickname" :value="item.id">
              <div style="display: flex;align-items:center;text-align:center;">
                <img :src="item.avatar" style="width: 26px;height:26px;border-radius:4px;margin-right:4px;">
                <span>{{ item.nickname }} / {{item.phone}} / {{item.name}}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="性别" prop="gender">
          <el-select v-model="form.gender">
            <el-option label="男" :value="1"/>
            <el-option label="女" :value="2"/>
          </el-select>
        </el-form-item>

        <el-form-item label="包餐备注名" prop="catered_name">
          <el-input v-model="form.catered_name"></el-input>
        </el-form-item>

        <el-form-item label="次数" prop="num">
          <el-input-number v-model="form.num" controls-position="right" :min="0" :precision="0"></el-input-number>
        </el-form-item>

        <el-form-item label="有效时间">
          <el-date-picker v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->

    <!-- 导入包餐学生 -->
    <el-dialog title="导入数据预览" :visible.sync="importCateredStudentVisible" width="60%" :close-on-click-modal="false">
      <div style="height: 500px" class="scrollbar">
        <el-scrollbar style="height: 500px">
          <el-table :data="importCateredStudentData" style="width: 1100px">
            <el-table-column label="序号" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.index || "" }}
              </template>
            </el-table-column>

            <el-table-column label="手机号码" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.phone || "" }}
              </template>
            </el-table-column>

            <el-table-column label="性别" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.gender || "" }}
              </template>
            </el-table-column>

            <el-table-column label="包餐备注名" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.catered_name || "" }}
              </template>
            </el-table-column>

            <el-table-column label="次数" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.num || "" }}
              </template>
            </el-table-column>

            <el-table-column label="开始日期" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.start_date || "" }}
              </template>
            </el-table-column>

            <el-table-column label="结束日期" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.end_date || "" }}
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="importCateredStudentVisible = false">取消</el-button>
        <el-button type="primary" @click="importCateredStudentSaveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 导入包餐学生 -->
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";
  import {excel2Json} from "@/utils/excel";
  import downloadExcel from "vue-json-excel";

  export default {
    components: {
      downloadExcel
    },
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          school_id: "",
          name: "",
          phone: "",
          gender: "",
          status: "",
          start_date: "",
          end_date: "",
        },
        dialogFormVisible: false,
        dialogStatus: "",
        textMap: {
          update: "修改",
          create: "新增",
        },
        btnLoading: false,
        form:{
          id: "",
          school_id: "",
          user_id: "",
          gender: "",
          catered_name: "",
          num: 0,
          start_date: "",
          end_date: "",
        },
        rules: {
          user_id: [{ required: true, message: "用户不能为空", trigger: "change" }],
          gender: [{ required: true, message: "性别不能为空", trigger: "change" }],
          catered_name: [{ required: true, message: "包餐备注名不能为空", trigger: "change" }],
          num: [{ required: true, message: "次数不能为空", trigger: "change" }],
        },
        userFilterLoading: false,
        users: [],
        dateArr: null,
        // 导入包餐学生
        importCateredStudentData: [],
        importCateredStudentVisible: false,
        dateArrQuery: null,
        boy: {
          issuedQuantity: 0,
          usedQuantity: 0,
          remainingQuantity: 0,
        },
        girl: {
          issuedQuantity: 0,
          usedQuantity: 0,
          remainingQuantity: 0,
        },
        json_fields: {
          openid: {
            field: "user.openid",
            callback: (value) => {
              return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
            }
          },
          昵称: "user.nickname",
          姓名: "user.name",
          手机号码: {
            field: "user.phone",
            callback: (value) => {
              return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
            }
          },
          性别: {
            field: "gender",
            callback: (value) => {
              if (value === 1) return "男";
              else if (value === 2) return "女";
              else return "";
            },
          },
          包餐备注名: "catered_name",
          剩余次数: "num",
          开始日期: {
            field: "start_date",
            callback: (value) => {
              return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
            }
          },
          结束日期: {
            field: "end_date",
            callback: (value) => {
              return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
            }
          },
          状态: {
            field: "status",
            callback: (value) => {
              if (value === 0) return "未开始";
              else if (value === 1) return "生效中";
              else if (value === 2) return "已截止";
              else return "";
            }
          },
          创建时间: {
            field: "created_at",
            callback: (value) => {
              return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
            }
          },
        },
        exportLoading: false,
      };
    },
    created() {
      this.getList();
    },
    computed: {
      ...mapGetters(["schools","school_id","user"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
      },
      dateArr(newVal, oldVal) {
        if (newVal) {
          this.form.start_date = newVal[0];
          this.form.end_date = newVal[1];
        } else {
          this.form.start_date = "";
          this.form.end_date = "";
        }
      },
      dateArrQuery(newVal, oldVal) {
        if (newVal) {
          this.listQuery.start_date = newVal[0];
          this.listQuery.end_date = newVal[1];
        } else {
          this.listQuery.start_date = "";
          this.listQuery.end_date = "";
        }
      }
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        this.listLoading = true;
        request({
          url: "/api/backend/cateredStudent/list",
          method: "get",
          params: this.listQuery,
          timeout: 6000000,
        }).then(response => {
          this.list = response.data.list.data;
          this.total = response.data.list.total;
          this.boy = response.data.boy;
          this.girl = response.data.girl;
          this.listLoading = false;
        });
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.listQuery.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.listQuery.page = val;
        this.getList();
      },
      resetForm() {
        this.form = {
          id: "",
          school_id: "",
          user_id: "",
          gender: "",
          catered_name: "",
          num: 0,
          start_date: "",
          end_date: "",
        }
        this.btnLoading = false;
        this.dateArr = null;
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = "create"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dateArr = [this.form.start_date,this.form.end_date];
        this.dialogStatus = "update"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      saveData() {
        this.form.school_id = this.school_id;
        if (!this.form.school_id) {
          this.$message({
            type: "warning",
            message: "请先选择学校"
          });
          return;
        }
        if (!this.form.start_date) {
          this.$message({
            type: "warning",
            message: "开始日期不能为空"
          });
          return;
        }
        if (!this.form.end_date) {
          this.$message({
            type: "warning",
            message: "结束日期不能为空"
          });
          return;
        }

        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: "/api/backend/cateredStudent/save",
              method: "post",
              data: this.form
            }).then(() => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getList();
            }).catch(() => {
              this.btnLoading = false
            })
          }
        })
      },
      handleDelete(row) {
        this.$confirm("确认继续该操作", "提示", {
          type: "warning"
        }).then(() => {
          request({
            url: "/api/backend/cateredStudent/delete",
            method: "post",
            data: {
              id: row.id
            }
          }).then(()=>{
            this.getList();
            this.list.length <= 1 && this.listQuery.page > 1 ? this.listQuery.page-- : false;
            this.$message({
              type: "success",
              message: "操作成功"
            });
          })
        }).catch(() => {});
      },
      userFilter(val) {
        this.userFilterLoading = true;
        request({
          url: "/api/backend/user/list",
          method: "get",
          params: {
            page: 1,
            limit: 10,
            // school_id: this.school_id,
            keyword: val,
          }
        }).then(response => {
          this.users = response.data.data;
        }).finally(()=>{
          this.userFilterLoading = false
        })
      },
      // 消费记录
      handleCateredStudentOrder(row) {
        this.$router.push(`/catered/cateredStudentOrder?catered_student_id=${row.id}`);
      },
      // 包餐学生模板
      downloadCateredStudentTemplate() {
        let a = document.createElement("a");
        a.href = "/包餐学生模板.xlsx";
        a.download = "包餐学生模板.xlsx";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      // 导入包餐学生
      handleImportCateredStudent(file) {
        this.importCateredStudentData = [];
        file = file.raw;
        if (!(file.name.split(".")[1] === "xlsx" || file.name.split(".")[1] === "xls")) {
          this.$message.error("上传文件格式不正确，请检查选择的文件");
          return;
        }
        excel2Json(file, 1).then((excelData) => {
          // 处理业务
          if (excelData.length <= 0) {
            this.$message.error("数据不能为空");
            return;
          }
          if (excelData.length > 300) {
            this.$message.error(`数据批量处理限制300以内,当前数量${excelData.length}`);
            return;
          }
          excelData.forEach((element, index) => {
            this.importCateredStudentData.push({
              index: element["序号"]||"",
              phone: (element["手机号码"]||"").toString(),
              catered_name: element["包餐备注名"]||"",
              num: element["次数"]||"",
              gender: element["性别"]||"",
              start_date: element["开始日期"]||"",
              end_date: element["结束日期"]||"",
            });
          });
          this.importCateredStudentVisible = true;
        });
      },
      importCateredStudentSaveData() {
        if (!this.school_id) {
          this.$message({
            type: "warning",
            message: "请先选择学校"
          });
          return;
        }
        this.btnLoading = true;
        for (let index = 0; index < this.importCateredStudentData.length; index++) {
          const item = this.importCateredStudentData[index];
          for (var key in item) {
            if (!item[key]) {
              this.$message({
                type: "error",
                message: "请先完善数据",
              });
              this.btnLoading = false;
              return;
            }
          }
        }
        request({
          url: "/api/backend/cateredStudent/batchSave",
          method: "post",
          data: {
            school_id: this.school_id,
            data: this.importCateredStudentData,
          },
          timeout: 10 * 60 * 1000,
        }).then(() => {
          this.btnLoading = false;
          this.importCateredStudentVisible = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getList();
        }).catch(() => {
          this.btnLoading = false;
        });
      },
      handleExport() {
        if (!this.listQuery.start_date || !this.listQuery.end_date) {
          this.$message({
            type: "warning",
            message: "请选择时间段"
          });
          return;
        }

        let listQuery = JSON.parse(JSON.stringify(this.listQuery));
        listQuery.page = 1;
        listQuery.limit = this.total;
        this.exportLoading = true;
        return request({
          url: "/api/backend/cateredStudent/list",
          method: "get",
          params: listQuery,
          timeout: 6000000,
        }).then(response => {
          if (response.data.list.data.length <= 0) {
            this.$message({
              type: "warning",
              message: "暂无记录"
            });
            return;
          }
          return response.data.list.data;
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          this.exportLoading = false;
        });
      }
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .el-col{
    margin-bottom: 20px;
  }
  .box-card {
    text-align: center;
    .value {
      color: #546DFE;
      font-weight: bold;
      font-size: 26px;
    }
    .subtitle {
      font-size: 12px;
      color: gray;
      margin-top: 6px;
    }
  }
</style>
